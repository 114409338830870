.form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: auto;
  }
  
  .label {
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .fileInputContainer {
    margin-bottom: 16px;
    position: relative;
  }
  
  .fileInput {
    display: none; /* Hide the default file input */
  }
  
  .uploadBox {
    width: 100%;
    height: 100px;
    border: 2px dashed #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: #f8f9fa;
    border-radius: 5px;
  }
  
  .plusIcon {
    font-size: 24px;
    color: #007bff;
  }
  
  .fileList {
    margin-top: 10px;
  }
  
  .fileItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #e1e1e1;
    border-radius: 4px;
    margin-top: 5px;
  }
  
  .fileIcon {
    font-size: 20px;
  }
  
  .fileName {
    flex: 1;
    margin-left: 10px;
    max-width: calc(100% - 50px); /* Leave space for the remove button */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .removeButton {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    min-width: 50px; /* Set a minimum width for consistent button size */
    text-align: center;
  }
  
  .error {
    color: red;
    font-size: 12px;
  }
  
  .button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  
  
  